import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import logoMenu from "../images/logos/logoMenu.svg"
import logo130 from "../images/logos/newLogoMenu.svg"
import styles from "../styles/header.module.css"
import SideMenu from "../components/sideMenu"

const Header = () => {
  const [activeMenu, setActiveMenu] = useState(true)
  return (
    <>
      <SideMenu
        activeMenu={activeMenu}
        setActiveMenu={setActiveMenu}
      ></SideMenu>
      <header className={`${styles.header}`}>
        <Link to={`/`}>
          <img className={styles.logo130} src={logo130} alt="logo130" />
        </Link>
        <div
          onClick={() => {
            setActiveMenu(!activeMenu)
          }}
          className={styles.menuHeader}
        >
          {" "}
          <span>MENÚ</span>
          <img className={styles.menuIcon} src={logoMenu} alt="menuIcon" />
        </div>
      </header>
    </>
  )
}

export default Header
